import React, { useRef, useEffect } from 'react';

import Lottie from 'lottie-react';
import { useInView } from 'framer-motion';

import Animation from '../data-migration-animation.json';

const MobileLottie = ({ frames, index }) => {
  const mobileLottieRef = useRef(null);
  const mobilePositionLottieRef = useRef(null);
  const isInView = useInView(mobilePositionLottieRef, { amount: 0.7 });

  useEffect(() => {
    mobileLottieRef.current.goToAndStop(frames[index + 1][0], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isInView && mobileLottieRef.current.playSegments(frames[index + 1], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <div ref={mobilePositionLottieRef}>
      <Lottie
        lottieRef={mobileLottieRef}
        animationData={Animation}
        autoPlay={false}
        loop={false}
        style={{ maxWidth: '500px', width: '100%' }}
      />
    </div>
  );
};

export default MobileLottie;
