import useMediaQuery from '@material-ui/core/useMediaQuery';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import Section from '../../molecules/Section/Section';
import { breakpoints } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import {
  Content,
  Heading,
  HexWrapper,
  HexWrapperThree,
  HexWrapperTwo,
  LottieContainer,
  MobileSlideWrapper,
  SlidesContainer,
  Subhead,
  Wrapper,
} from './DataMigrationAnimation.styles';
import MobileLottie from './MobileLottie/MobileLottie';
import { Hex } from './Shopmonkey-Hexagon';
import Slide from './Slide/Slide';
import Animation from './data-migration-animation.json';

const DataMigrationAnimation = ({ component }) => {
  const { id, slides, heading, subhead } = component;
  const lottieRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  const frames = {
    1: [1, 47],
    2: [47, 225],
    3: [225, 300],
    4: [300, 360],
  };

  const mobileFrames = {
    1: [1, 47],
    2: [47, 225],
    3: [225, 360],
  };

  useEffect(() => {
    if (!isDesktop) return;

    switch (currentSlide) {
      case 1:
        lottieRef.current.playSegments(frames[1], true);
        break;
      case 2:
        lottieRef.current.playSegments(frames[2], true);
        break;
      case 3:
        lottieRef.current.playSegments(frames[3], true);
        break;
      case 4:
        lottieRef.current.playSegments(frames[4], true);
        break;
      default:
        lottieRef.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  useEffect(() => {
    isDesktop && lottieRef.current.goToAndStop(frames[1][0], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Section
      id={id}
      bgColor={colors.primary[900]}
      style={{ position: 'relative' }}
    >
      <Content>
        {heading && <Heading>{heading}</Heading>}
        {subhead && <Subhead dangerouslySetInnerHTML={{ __html: subhead }} />}
      </Content>
      <Wrapper>
        <HexWrapper>
          <Hex />
        </HexWrapper>
        <HexWrapperTwo>
          <Hex />
        </HexWrapperTwo>
        <HexWrapperThree>
          <Hex />
        </HexWrapperThree>
        {isDesktop && (
          <>
            <SlidesContainer>
              {slides &&
                slides.map((slide, ind) => (
                  <Slide
                    key={slide.id}
                    kicker={slide.kicker}
                    heading={slide.heading}
                    body={slide.body}
                    button={slide.button}
                    currentSlide={currentSlide}
                    setCurrentSlide={setCurrentSlide}
                    index={ind + 1}
                  />
                ))}
              <Slide
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                index={4}
              />
            </SlidesContainer>
            <LottieContainer>
              <Lottie
                lottieRef={lottieRef}
                animationData={Animation}
                loop={false}
                style={{ width: '500px' }}
              />
            </LottieContainer>
          </>
        )}

        {!isDesktop &&
          slides &&
          slides.map((slide, ind) => (
            <MobileSlideWrapper key={slide.id}>
              <MobileLottie frames={mobileFrames} index={ind} />
              <Slide
                key={slide.id}
                kicker={slide.kicker}
                heading={slide.heading}
                body={slide.body}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                index={ind + 1}
              />
            </MobileSlideWrapper>
          ))}
      </Wrapper>
    </Section>
  );
};

export default DataMigrationAnimation;
