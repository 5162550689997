import React, { useEffect, useRef } from 'react';

import { useInView } from 'framer-motion';

import CallToAction from '../../../molecules/CallToAction/CallToAction';

import {
  Body,
  Heading,
  Kicker,
  Wrapper,
  StickyContainer,
} from './Slide.styles';

const Slide = ({
  kicker,
  heading,
  body,
  button,
  currentSlide,
  setCurrentSlide,
  index,
}) => {
  const slideRef = useRef(null);
  const isInView = useInView(slideRef, { amount: 1 });

  useEffect(() => {
    isInView && setCurrentSlide(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <StickyContainer index={index}>
      <Wrapper ref={slideRef} currentSlide={currentSlide} index={index}>
        {kicker && <Kicker>{kicker}</Kicker>}
        {heading && <Heading>{heading}</Heading>}
        {body && <Body dangerouslySetInnerHTML={{ __html: body }} />}
        {button && (
          <CallToAction
            variant="secondary"
            size={button?.size}
            value={button?.label}
            link={button?.url}
            icon={button?.icon}
          />
        )}
      </Wrapper>
    </StickyContainer>
  );
};

export default Slide;
